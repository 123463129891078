import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const OurTeam = () => {

  return (
    <div className="py-[100px]" id="niza-team">
      <p className="text-[54px] font-[600] text-center text-white tracking-[-2.16px] max-sm:text-5xl">
        Our <span className="text-[#D2D22D]">Team</span>
      </p>
      <p className="text-[#E8E8E8CC] text-center pb-[54px] w-full max-w-[735px] mx-auto max-sm:text-sm max-sm:mt-2">
      Meet the dedicated team behind Niza.io! Our skilled professionals bring together expertise from various fields, ensuring we deliver the best possible experience. While you see our core members here, many more talented individuals work tirelessly in the background, contributing their unique skills to help us grow and innovate every day.  
      </p>
      <div
        className="select-none"
        style={{
          paddingBottom: "30px",
          position: "relative",
        }}
      >
        <Carousel
          additionalTransfrom={0}
          arrows={false}
          autoPlaySpeed={3000}
          centerMode={true}
          className="carouselTrack justify-start md:justify-center px-3"
          // dotListClass="carouselDots"
          draggable
          focusOnSelect={false}
          itemClass="!w-[265px] md:!w-[350px]"
          keyBoardControl
          minimumTouchDrag={80}
          pauseOnHover
          renderArrowsWhenDisabled={false}
          renderButtonGroupOutside={false}
          // renderDotsOutside
          responsive={{
            desktop: {
              breakpoint: {
                max: 3000,
                min: 1024,
              },
              items: 3,
            },
            mobile: {
              breakpoint: {
                max: 464,
                min: 0,
              },
              items: 1,
            },
            tablet: {
              breakpoint: {
                max: 1024,
                min: 464,
              },
              items: 2,
            },
          }}
          rewind={false}
          rewindWithAnimation={false}
          rtl={false}
          shouldResetAutoplay
          showDots={false}
          sliderClass=""
          slidesToSlide={1}
          swipeable
        >
            <div className="overflow-hidden rounded-t-2xl">
                <img
                    src="ourTeam/enis_400x400.jpg"
                    className="pointer-events-none"
                    alt="Background"
                    style={{
                    display: "block",
                    margin: "auto",
                    }}
                />
                <div className="bg-[#21262D] rounded-b-2xl">
                    <div className="flex flex-col gap-1 pl-6 pt-4 pb-7">
                        <a href="https://x.com/enisbushati" target="_blank" className="text-white font-semibold text-xl md:text-2xl">Enis Bushati</a>
                        <p className="text-[#D2D22D] font-normal">Global CEO / Founder</p>
                    </div>
                </div>
            </div>
            <div>
                <img
                    src="ourTeam/bgImage.svg"
                    className="pointer-events-none"
                    alt="Background"
                    style={{
                    display: "block",
                    margin: "auto",
                    }}
                />
                <div className="bg-[#21262D] rounded-b-2xl">
                    <div className="flex flex-col gap-1 pl-6 pt-4 pb-7">
                        <p className="text-white font-semibold text-xl md:text-2xl">Enkelejde Trushi</p>
                        <p className="text-[#D2D22D] font-normal">Director</p>
                    </div>
                </div>
            </div>
            <div>
                <img
                    src="ourTeam/bgImage.svg"
                    className="pointer-events-none"
                    alt="Background"
                    style={{
                    display: "block",
                    margin: "auto",
                    }}
                />
                <div className="bg-[#21262D] rounded-b-2xl">
                    <div className="flex flex-col gap-1 pl-6 pt-4 pb-7">
                        <p className="text-white font-semibold text-xl md:text-2xl">Valdemar Skorinkevič</p>
                        <p className="text-[#D2D22D] font-normal">AML Officer</p>
                    </div>
                </div>
            </div>
        </Carousel>
        {/* <div className="container">
          <div className="float-right mt-5 text-custom-primary w-fit px-4 py-3 flex gap-3 items-center bg-[#283039] rounded-lg">
            View all
            <FontAwesomeIcon icon={faArrowRight} className="text-white" />
          </div>
        </div> */}
        {/* <div className="bg-custom-primary absolute left-[42%]">Test</div>
        <div className="bg-custom-primary absolute right-[42%]">Test</div> */}
      </div>
    </div>
  );
};

export default OurTeam;
