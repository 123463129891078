import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import parse from "html-react-parser";
import { Link } from "react-router-dom";
import { Hero as CommonHero } from "../components/common/Hero";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faApple, faGooglePlay } from "@fortawesome/free-brands-svg-icons";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";

const Hero = () => {
  const { t } = useTranslation();

  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 950) {
        setModalOpen(true);
      } else {
        setModalOpen(false);
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const videoRef = useRef(null);

  useEffect(() => {
    const video = videoRef.current;
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.5, // Adjust threshold as needed
    };

    const callback = (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          video.play();
        } else {
          video.pause();
        }
      });
    };

    const observer = new IntersectionObserver(callback, options);
    observer.observe(video);

    return () => {
      observer.unobserve(video);
    };
  }, []);

  const basicInformation = [
    { title: "$1.2B", desc: "24h Trading Volume" },
    { title: "200+", desc: "Cryptocurrencies Listed" },
    { title: "1M+", desc: "Registered Users" },
    { title: "0.1%", desc: "Maker & Taker fee" },
  ];

  const buyNizaOnWebsites = [
    {
      icon: "/icons/NizaGlobal.svg",
      text: "NIZA GLOBAL",
      link: "https://app.niza.io/",
      color: "#ffffff",
    },
    {
      icon: "/icons/Uniswap.svg",
      text: "Uniswap",
      link: "https://app.uniswap.org/tokens/ethereum/0xb58e26ac9cc14c0422c2b419b0ca555ee4dcb7cb",
      color: "#FF278E",
    },
    {
      icon: "/icons/MEXC.svg",
      text: "MEXC",
      link: "https://www.mexc.com/exchange/NIZA_USDT",
      color: "#B5C7EF",
    },
    {
      icon: "/icons/LBANK.svg",
      text: "LBANK",
      link: "https://www.lbank.com/trade/niza_usdt/",
      color: "#FFD634",
    },
    {
      icon: "/icons/BitMart.svg",
      text: "BitMart",
      link: "https://www.bitmart.com/trade/en-US?symbol=NIZA_USDT&layout=pro",
      color: "#91E0DD",
    },
    {
      icon: "/icons/Gateio.svg",
      text: "Gate.io",
      link: "https://www.gate.io/trade/NIZA_USDT",
      color: "#2354E6",
    },
  ];

  return (
    <>
      <CommonHero
        imgs={
          <video
            ref={videoRef}
            src={
              "/hero-video.mp4"
            }
            autoPlay
            playsInline
            loop
            muted
            className="absolute top-0 right-0 w-full h-[1000px] md:h-full object-cover z-0"
          />
        }
        shadow={true}
      >
        <div
          className="xl:flex items-center w-[90%] md:container m-auto mt-40 max-sm:mt-42"
          style={{ fontFamily: "Geist Variable" }}
        >
          <div className="w-[100%]">
            <div className="flex max-xl:flex-col-reverse xl:items-center xl:gap-20 w-full">
              <p className="text-5xl max-sm:text-4xl lg:text-6xl font-semibold text-white mb-8 tracking-[-2.56px] !leading-[72px] max-sm:!leading-10 max-sm:mb-6">
                {t("home.hero.title.more")}{" "}
                <span className="text-custom-primary">
                  {t("home.hero.title.profits")}
                </span>
                <br />
                <span className="text-custom-primary">
                  {t("home.hero.title.lower")}
                </span>{" "}
                {t("home.hero.title.fees")}
              </p>
              <div
                className="w-full max-w-[680px] flex items-center py-2 px-4 max-lg:mt-20 lg:mb-14 rounded-lg border border-[#ffffff21] bg-[#00000052] backdrop-blur-lg max-sm:px-3 max-sm:flex-col max-sm:gap-2 max-sm:mt-0 max-sm:mb-8"
              >
                <div className="flex gap-2 items-center">
                  <img src="/icons/NizaLogo.svg" alt="Niza Logo" />
                  <p className="text-white text-xl font-normal max-md:text-lg max-sm:text-lg">
                    Buy <span className="font-semibold">NIZA Coin</span> on
                  </p>
                </div>
                <div className="w-px h-12 bg-[#616161] mx-3 max-sm:hidden"></div>
                <div className="flex flex-1 justify-between gap-3 max-sm:gap-2">
                  {buyNizaOnWebsites.map((item, indx) => (
                    <Link
                      target="_blank"
                      to={item.link}
                      className="flex flex-col gap-1 p-1 rounded-md justify-end items-center text-center hover:bg-white/10"
                    >
                      <img src={item.icon} alt={item.text} className="h-8" />
                      <p
                        className={
                          `font-bold ` +
                          (indx === 0 ? "text-[7px]" : "text-[10px]")
                        }
                        style={{ color: item.color }}
                      >
                        {indx === 0 ? (
                          <span>
                            NIZA <span className="text-[#F2F230]">GLOBAL</span>
                          </span>
                        ) : (
                          item.text
                        )}
                      </p>
                    </Link>
                  ))}
                </div>
              </div>
            </div>
            <p className="text-xl text-[#F8FAFC] font-medium tracking-[-0.36px] mb-3 max-sm:text-base">
              {t("home.hero.subtitle")}{" "}
              <span className="text-custom-primary">
                {t("home.hero.subtitle_fee")}
              </span>
            </p>
            <p className="text-xl text-[#F8FAFC] font-medium tracking-[-0.36px] max-sm:text-base max-w-lg">
              {parse(t("home.hero.description"))}
            </p>

            <div className="flex items-center my-12 gap-4 max-sm:gap-2 max-sm:my-6">
              <div className="flex gap-4 max-md:flex-col max-sm:gap-2">
                <div className="flex gap-2 max-sm:flex-col h-12 max-sm:h-full">
                  <input
                    type="text"
                    placeholder="Email Address"
                    className="py-2.5 px-3.5 rounded-lg border placeholder:text-white border-[#FFFFFF33] w-80 backdrop-blur-sm bg-white/10 text-white 
                      focus-visible:outline-none focus-visible:border-white/50 max-sm:w-72
                    "
                  />
                  <div className="flex">
                    <Link
                      to={process.env.REACT_APP_SIGN_UP}
                      className="text-black text-lg bg-custom-primary hover:bg-custom-primary-dark transition-colors cursor-pointer rounded-lg font-semibold 
                        py-2 px-4 flex items-center
                      "
                    >
                      {t("home.hero.button")}
                    </Link>
                  </div>
                </div>
                <Popup
                  trigger={
                    <Link
                      to={"/"}
                      className="flex gap-2 items-center px-5 py-3 rounded-lg border border-white/20 backdrop-blur-sm bg-white/10 text-white 
                          text-base hover:bg-[#F2F2301A] hover:border-[#F2F23033] h-12 w-48 justify-center
                        "
                    >
                      Download App
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="21"
                        viewBox="0 0 20 21"
                        fill="none"
                      >
                        <path
                          d="M3.125 4.23291C3.125 3.71541 3.545 3.29541 4.0625 3.29541H7.8125C8.33 3.29541 8.75 3.71541 8.75 4.23291V7.98291C8.75 8.50041 8.33 8.92041 7.8125 8.92041H4.0625C3.81386 8.92041 3.5754 8.82164 3.39959 8.64582C3.22377 8.47001 3.125 8.23155 3.125 7.98291V4.23291ZM3.125 12.3579C3.125 11.8404 3.545 11.4204 4.0625 11.4204H7.8125C8.33 11.4204 8.75 11.8404 8.75 12.3579V16.1079C8.75 16.6254 8.33 17.0454 7.8125 17.0454H4.0625C3.81386 17.0454 3.5754 16.9466 3.39959 16.7708C3.22377 16.595 3.125 16.3566 3.125 16.1079V12.3579ZM11.25 4.23291C11.25 3.71541 11.67 3.29541 12.1875 3.29541H15.9375C16.455 3.29541 16.875 3.71541 16.875 4.23291V7.98291C16.875 8.50041 16.455 8.92041 15.9375 8.92041H12.1875C11.9389 8.92041 11.7004 8.82164 11.5246 8.64582C11.3488 8.47001 11.25 8.23155 11.25 7.98291V4.23291Z"
                          stroke="#F3F4F6"
                          stroke-width="1.61628"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M5.625 5.79541H6.25V6.42041H5.625V5.79541ZM5.625 13.9204H6.25V14.5454H5.625V13.9204ZM13.75 5.79541H14.375V6.42041H13.75V5.79541ZM11.25 11.4204H11.875V12.0454H11.25V11.4204ZM11.25 16.4204H11.875V17.0454H11.25V16.4204ZM16.25 11.4204H16.875V12.0454H16.25V11.4204ZM16.25 16.4204H16.875V17.0454H16.25V16.4204ZM13.75 13.9204H14.375V14.5454H13.75V13.9204Z"
                          stroke="#F3F4F6"
                          stroke-width="1.61628"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </Link>
                  }
                  position={"right bottom"}
                  modal={modalOpen}
                >
                  <div
                    className="flex flex-col gap-3 items-center"
                    style={{ fontFamily: "Geist Variable" }}
                  >
                    <div className="flex flex-col gap-2 items-center">
                      <div className="flex flex-col gap-2 items-center">
                        <p className="text-sm">iOS and Android</p>
                        <p className="font-medium text-sm">
                          Scan to download App
                        </p>
                      </div>
                      <img
                        src="/icons/Niza_QR.png"
                        alt="QR Code"
                        className="w-52"
                      />
                    </div>
                    <div className="flex flex-col gap-2 items-center w-full">
                      <Link
                        to={
                          "https://apps.apple.com/us/app/niza-buy-btc-eth-500/id6468353879"
                        }
                        className="text-black font-semibold flex items-center justify-center gap-3 bg-custom-primary rounded-lg py-2 px-4 w-full hover:bg-custom-primary-dark"
                      >
                        <FontAwesomeIcon
                          icon={faApple}
                          className="text-black h-6"
                        />
                        App Store
                      </Link>
                      <Link
                        to={
                          "https://play.google.com/store/apps/details?id=io.niza.app"
                        }
                        className="text-black font-semibold flex items-center justify-center gap-3 bg-custom-primary rounded-lg py-2 px-4 w-full hover:bg-custom-primary-dark"
                      >
                        <FontAwesomeIcon
                          icon={faGooglePlay}
                          className="text-black h-5"
                        />
                        Play Store
                      </Link>
                    </div>
                  </div>
                </Popup>
              </div>
            </div>

            <div className="flex items-center gap-8 max-sm:flex-wrap">
              {basicInformation.map((item) => (
                <div className="flex flex-col items-start gap-1">
                  <p className="font-semibold text-2xl text-gray-50">
                    {item.title}
                  </p>
                  <p className="text-sm text-gray-300">{item.desc}</p>
                </div>
              ))}
            </div>
          </div>

          {/* <div className="w-full xl:w-1/3 flex justify-center relative mt-[74px]"></div> */}
        </div>
      </CommonHero>
      <div className="relative"></div>
    </>
  );
};

export default Hero;
