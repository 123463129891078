import React from "react";
import { useContext } from "react";
import { OpeningSoonContext } from "../context/openingSoonContext";
import { Helmet } from "react-helmet";
// COMPONENTS
import Hero from "../components/Hero";
import Announcement from "../components/home/Announcement";
import Activities from "../components/home/Activities";
import CryptoTable from "../components/home/CryptoTable";
import NizaTrade from "../components/home/NizaTrade";
import AccountTypes from "../components/home/AccountTypes";
import WhyUs from "../components/home/WhyUs";
import StartNow from "../components/home/StartNow";
import CallToAction from "../components/home/CallToAction";
import OpeningSoon from "../components/alert/OpeningSoon";
import AllCoins from "../components/home/AllCoins";
import TextCards from "../components/home/TextCards";
import NizaComunties from "../components/home/NizaComunties";
import NizaAppTrade from "../components/home/NizaAppTrade";
import Banking from "../components/home/Banking";
// import ProductSection from "../components/home/ProductSection";
// import Carousel from "../components/home/Carousel";

const Home = () => {
  const { warning } = useContext(OpeningSoonContext);

  return (
    <>
      <Helmet>
        <title>Niza - Home</title>
      </Helmet>
      <div className="relative overflow-x-hidden">
        {warning ? <OpeningSoon /> : null}
        <Hero />
        <Announcement />
        <Activities />
        <TextCards />
        {/* <ProductSection /> */}
        <NizaAppTrade />
        <NizaTrade />
        <CryptoTable />
        <Banking />
        <AccountTypes />
        <WhyUs />
        <AllCoins />
        <StartNow />
        <NizaComunties />
        <CallToAction />
      </div>
    </>
  );
};

export default Home;
